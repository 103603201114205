.dashboard-section {
  width: 100%;
  height: 160%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  > .dash-top {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    > .date-btn {
      > Button {
        color: #25324b;
        background-color: transparent;
        padding: 10px 15px;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        border: 1px solid #d6ddeb;
        border-radius: 6px;
        > i {
          color: $nd-primary-color;
        }
      }
    }
  }
 
  .job-statics-box {
    width: 60%;
    height: 450px;
    > div {
      > .year-btn {
        display: flex;
        background-color: #e9ebfd;
        padding: 8px;
        gap: 2px;
        > .active {
          background-color: white;
        }
        Button {
          border: none;
          border-radius: 0;
          background-color: transparent;
          color: $nd-primary-color;
          font-weight: 500;
        }
      }
    }
    .job-statistic-navbar {
      padding: 0px 20px;

      box-shadow: 0px -1px 0px 0px #d6ddeb inset;
      > .nav-job {
        width: 40%;
        color: #7c8493;
        font-weight: 600;

        > .active {
          border-bottom: 4px solid $nd-primary-color;
        }
        .nav-item {
          padding-bottom: 8px;
        }
      }
    }
  }
  .open-job-applicant-summary {
    > .open-job-box {
      
      height: 160px;
      padding: 24px 80px 24px 24px;
      > .big-number {
        font-size: 65px;
        font-weight: 400;
      }
    }
    > .applicant-summary-box {
      height: 265px;
      padding: 24px 40px 24px 24px;
     
      > .big-number {
        font-size: 65px;
        font-weight: 400;
      }
    }
  }
  .job-update-box {
   
    > .update-header {
      padding: 20px;
      border-bottom: 1px solid #d6ddeb;
    }
   
    >div{
      > .job-list-box {
        padding: 24px;
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        > div {
          .green-slider {
            width: 40%;
            height: 6px;
            background-color: #16a799;
          }
          .gray-slider {
            width: 60%;
            height: 6px;
            background-color: #d6ddeb;
          }
        }
      }
    }
  }
}

.footer{
    width: 100%;
    background:  linear-gradient(266deg, #575757 -3.26%, #393939 50.21%);
    padding: 70px 0 30px 0;
    h4{
        color: #fff;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .para{
        color: #D6DDEB;
        a{
            color: rgb(255, 255, 255);
            text-decoration: none;
        }
    }
    .row{
        div{
            .input-email-box{
                background-color: #fff;
                padding: 10px 16px;
              
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 30px;
                i{
                    color: #A8ADB7;
                    font-size: 17px;
                   
                }
                input{
                    border: none;
                    outline: none;
                    background-color: transparent;
                    &::placeholder{
                        color: #A8ADB7;
                        font-size: 15px;
                    }
                }
            }
        
        }
        #horizontal-line{
            opacity: .5;
            margin: 50px 0;
         }

         .copyright{
            display: flex;
            justify-content: space-between;
            .copy-text{
                color: #dde0e7;

            }
            .social-icons{
                display: flex;
                gap: 20px;
                .icon{
                    width: 40px;
                    height: 40px;
                    background-color: #575757;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    i{
                        color: #fff;
                    }
                }
            }
         }
    }


}
.find-company-section {
	a {
		text-decoration: none;
		color: #fff;
	}

	>.first-container {
		padding: 80px 255px 80px 210px;
		display: flex;

		flex-direction: column;

		// background-color: rgba(127, 255, 212, 0.185);
		background: #eefaf7;

		>.find-talent-upper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;

			h1 {
				font-weight: 700;
				margin: 0;

				>span {
					color: $nd-primary-color;
				}
			}
		}

	}

	.container {
		>.recommended-section {
			margin-top: 60px;

			>.first-row {
				margin-bottom: 30px;

				h3 {
					font-weight: 700;
				}
			}

			>.company-boxes {
				display: flex;
				flex-wrap: wrap;

				gap: 25px;

				.company-box {
					padding: 18px;
					width: 30% !important;


					a {
						.brand-job {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.brand-icon {
								padding: 0;
								margin: 0;

								img {
									padding: 0;
									margin: 0;
								}
							}
						}

						h4 {
							font-weight: 700;
							color: #25324b;
							margin: 12px 0;
						}

						Button {
							padding: 8px 15px;
							font-weight: 600;
						}
					}
				}
			}
		}

		// poster-job css
		>.posting-job-poster {
			display: flex;

			margin: 80px 0 0 0;

			gap: 40px;
			justify-content: space-between;
			background-color: $nd-primary-color;
			padding: 70px 70px 0px 70px;
			border-radius: 25px;
			clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%, 0 18%);

			>.start-post-job {
				padding: 30px 20px;
				line-height: 3;
				gap: 20px;

				h1 {
					color: #fff;
					font-weight: 600;
				}

				p {
					color: #fff;
					font-size: 15px;
				}

				Button {
					border: none;

					background-color: #fff;

					padding: 12px 24px;
					border-radius: 30px;
					align-items: center;

					a {
						font-size: 15px;
						font-weight: 700;
						color: $nd-primary-color;
					}
				}
			}
		}
	}

	>.footer-section {
		background-color: #f8f8fd;
		border-radius: 0px;

		padding: 70px 0;
		margin-top: 60px;
		clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%, 0 10%);

		>.container {
			h3 {
				font-weight: 700;
				color: #25324b;
			}

			//    slider section css
			.category-slider {
				margin-top: 30px;
				// display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
				gap: 30px;
				position: relative;

				.slick-slide {
					padding: 10px;
				}

				.right-click-btn {
					position: absolute;
					top: 35%;
					right: -45px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 40px;
					width: 40px;
					cursor: pointer;
					background-color: $nd-primary-color;
					border-radius: 50%;

					i {
						color: #fff;
					}
				}

				.left-click-btn {
					position: absolute;
					top: 35%;
					left: -45px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 40px;
					width: 40px;
					cursor: pointer;
					background-color: $nd-primary-color;
					border-radius: 50%;

					i {
						color: #fff;
					}
				}

				.slider-box {
					min-width: 240px;
					min-height: 180px;
					display: flex !important;
					flex-direction: column;
					gap: 30px;
					padding: 30px 0 30px 30px;
					background-color: #fff;
					cursor: pointer;

					i {
						color: $nd-primary-color;
						font-size: 25px;
					}

					h4 {
						color: #25324b;
					}
				}

				.active {
					background-color: $nd-primary-color;

					i,
					h4 {
						color: #fff;
					}
				}
			}

			// results css
			>.results-section {
				margin-top: 50px;

				>div {
					display: flex;
					gap: 10px;
					align-items: center;

					>.result-icon {
						background-color: #fff;
						height: 45px;
						width: 45px;
						border-radius: 50%;
						display: flex;
						cursor: pointer;
						align-items: center;
						justify-content: center;
						color: $nd-primary-color;
					}

					h4 {
						font-weight: 700;
					}
				}

				.category-boxes {
					margin-top: 30px;
					margin-bottom: 15px;
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					gap: 98px;

					>.category-box {
						background-color: #fff;
						justify-content: center;
						width: 250px;
						height: 220px;
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: center;

						.company-image {
							margin-bottom: 10px;
						}

						h4 {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}
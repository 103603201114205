.find-talent {
  a:not(.page-link) {
    text-decoration: none;
    color: #fff;
  }
  > .first-container {
    padding: 80px 255px 80px 210px;
    display: flex;

    flex-direction: column;

    // background-color: rgba(127, 255, 212, 0.185);
    background: #eefaf7;

    > .find-talent-upper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      h1 {
        font-weight: 700;
        margin: 0;
       
        > span {
          color: $nd-primary-color;
        }
      }
    }
   
  }
  .allJobs-container {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    > .dropdown-section {
      width: 18%;
     
      Button {
        background-color: transparent;
        border: none;
        padding: 0;

        i {
          color: #25324b;
        }
      }

      form {
        input[type="checkbox"] {
          width: 19px;
          height: 19px;
         
        }
        div {
          display: flex;
          margin: 0px 0 10px 0;
          align-items: center;
        }
      }
    }
    .allJobs-section {
      width: 77%;
      .first-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .all-jobs {
          h3 {
            font-weight: 700;
            color: #25324b;
            margin: 3px 0;
          }
        }
        .sorted-by {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            select {
              width: 135px;
              outline: none;
              border: none;
              cursor: pointer;

              color: #25324b;
              font-weight: 500;
            }
          }
          Button {
            background-color: transparent;
            padding: 8px 12px;
            margin: 0;
            a {
              i {
                font-size: 25px;
                color: #25324b;
              }
            }

            border: none;
          }
          .active {
            background-color: #1fa2ff1f;
            a {
              i {
                font-size: 25px;

                color: $nd-primary-color;
              }
            }
          }
        }
      }
      .box-assistant {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 16px 25px;
        align-items: center;
        margin-bottom: 15px;
        &:hover {
          box-shadow: var(--bs-box-shadow);
        }
      }
      .end-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin: 30px 0 70px 0;
        Button {
          border: none;
          background-color: transparent;
          color: #515b6f;
          font-weight: 600;
          font-size: 15px;
          padding: 10px 20px;
        }
        .active {
          background-color: $nd-primary-color;
          color: #fff;
        }
      }
    }
  }
}

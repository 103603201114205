@import "fonts";

// Set default body styles
body {
  font-family: $font-family-inter !important;
  font-weight: 400 !important;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-inter;
  font-weight: 600;
  color: #25324b;
}

// Mixins for common text styles
@mixin bold-text {
  font-weight: 700;
}

@mixin italic-text {
  font-style: italic;
}

// Usage of mixins
strong {
  @include bold-text;
}

em {
  @include italic-text;
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
}

.text-blue {
  color: rgb(45, 150, 231);
  font-size: 16px;
  font-weight: 500;
}
/* Style for the input field */
.autocomplete-input {
    width: 100%;
    
}

/* Style for the suggestions list */
.suggestions-list {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 1000;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 20px;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
}

.suggestions-list li {
    padding: 8px;
    cursor: pointer;
}

/* Style for highlighting active suggestion */
.suggestion-active,
.suggestions-list li:hover {
    background-color: #f7f7f7;
}
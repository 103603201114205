.joblist-section {
  height: 150%;
  margin:24px;

  border: 1px solid #d6ddeb;
  > .joblist-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    padding: 30px 24px;
    > div {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
      .search-box {
        padding: 7px 12px;
        display: flex;
        gap: 10px;
        align-items: center;
        > i {
          color: #a8adb7;
          font-size: 19px;
        }
        > input {
          border: none;
          outline: none;
        }
      }
      Button {
        background-color: transparent;
        font-weight: 500;
        color: #25324b;
        padding: 8px 18px;
      }
    }
  }
}

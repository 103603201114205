// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
//   color: #212223;
//   font-family: $font-family-inter;
// }

.form-check-input {
	width: 22px;
	height: 22px;
	border-radius: 4px;
	border: 2px solid #d6ddeb;
}

label {
	color: #515b6f;
	font-family: $font-family-inter;
	font-size: 15px;
	padding-left: 16px;
	margin-top: 3px;
	font-style: normal;
	font-weight: 400;
}

.post-job-section {
	width: 100%;
	padding: 24px 32px;
	height: 175%;

	#input {
		color: #25324b;
		font-size: 15px;
		padding: 10px 14px;

		&::placeholder {
			color: #a8adb7;
		}
	}

	.para {
		color: #7c8493;
		font-size: 15px;
	}

	>.back-btn {
		color: #25324b;
		font-family: $font-family-inter;
		font-size: 27px;
		display: flex;
		align-items: center;

		font-style: normal;
		font-weight: 600;
		gap: 8px;

		Button {
			background-color: transparent;
			font-size: 27px;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;

			i {
				color: #25324b;
				font-weight: 600;
			}
		}

		.heading {
			color: #25324b;
			font-size: 30px;
			font-weight: 600;
			font-family: $font-family-inter;
			font-style: normal;
		}
	}

	>.steps {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 24px 0;
		padding: 18px;
		border-radius: 4px;
		border: 1px solid #d6ddeb;
		background: #fff;

		>.step1,
		.step2,
		.step3 {

			p,
			h5 {
				color: #7c8493;
			}
		}
	}
}

.step-icon {
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e9ebfd;
	border-radius: 50%;

	i {
		font-size: 20px;
		color: #7c8493;
	}
}

.active {
	.step-info {
		>h5 {
			color: #25324b;
		}

		>p {
			color: #1fa0ff;
		}
	}

	.step-icon {
		background-color: #1fa0ff;

		i {
			color: white;
		}
	}

	p {
		color: #1fa0ff;
	}
}

.step-info {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.range-btn {
	display: flex;
	padding: 8px 12px 6px 5px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #d6ddeb;
	background: #fff;

	h6 {
		padding: 0;
		margin: 0;
	}
}

.form-select {
	color: #7c8493;
	font-size: 15px;
	padding: 8px 12px;
}

.add-skill-btn {
	display: flex;
	width: 130px;
	align-items: center;
	gap: 10px;
	padding: 8px 12px;
	color: #1fa0ff;
	border-radius: 4px;
	border: 1px solid #ccccf5;

	span {
		font-size: 15px;
		font-weight: 600;
	}
}

.close-btn {
	span {
		display: flex;
		font-size: 15px;
		align-items: center;
		gap: 10px;
		padding: 8px 12px;
		color: #1fa0ff;
		border-radius: 4px;
		border: 1px solid #ccccf5;
	}
}

.end-btn {
  display: flex;
  justify-content: flex-end;
}


.quill-input {
	.ql-container {
		height: 150px;
	}
}

// .icons-selection {
// 	display: flex;
// 	justify-content: space-around;
// 	padding-top: 10px;

// 	span {
// 		padding: 2px;
// 		cursor: pointer;
// 		border: 1px solid #d6ddeb;
// 		border-radius: 50%;
// 		height: 40px;
// 		width: 40px;
// 		justify-content: center;
// 		align-items: center;
// 		display: flex;

// 		&:hover {
// 			background: rgb(45, 150, 231);
// 			color: white;
// 		}
// 	}
// }

.icon-selector {
	padding: 5px;
	margin: 2px;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	&.selected-icon {
		border-color: #007bff; // Or use a class from Bootstrap for blue border
		background-color: #e7f1ff; // Light blue background for selected icon
	}

	i {
		color: #007bff; // Bootstrap primary color
	}

	&:hover {
		background-color: #f8f9fa; // Bootstrap hover background color
	}
}
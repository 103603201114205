.dashboard-page {
  a {
    text-decoration: none;
  }
  .first-container {
    padding: 50px 0px 60px 0;
    background-color: rgba(127, 255, 212, 0.185);
    .container {
      display: flex;
      flex-direction: column;
      gap: 35px;
      .pages-links {
        font-size: 15px;
        a {
          color: #7c8493;
        }
      }
      .first-section-wish {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;

        border-radius: 12px;
        background: #fff;
        p {
          padding: 0;
          margin: 0;
        }
        .calender {
          background-color: #fff;
          padding: 10px 15px;
          border-radius: 8px;
          border: 1px solid #d6ddeb;
          font-size: 15px;
          cursor: pointer;
          color: #25324b;
          i {
            margin: 0 0px 0 10px;
            color: #4640de;
          }
        }
      }
    }
  }
  .dashboard-container{
    padding: 72px 0;
  }
}

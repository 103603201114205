.filter-company-section{
  a:not(.page-link) {
        text-decoration: none;
        color: #fff;
      }
      > .first-container {
        padding: 80px 255px 80px 210px;
    display: flex;

    flex-direction: column;

    background-color: rgba(127, 255, 212, 0.185);

    > .find-talent-upper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      h1 {
        font-weight: 700;
        margin: 0;
       
        > span {
          color: $nd-primary-color;
        }
      }
    }
      }


    //   container css


    .allJobs-container {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        > .dropdown-section {
          width: 18%;
          h6 {
            i {
              font-size: 13px;
            }
          }
          Button{
            background-color: transparent;
            border: none;
            padding: 0;
            i{
              color: #25324b;
                      }
          }
    
          form {
            input[type="checkbox"] {
              width: 19px;
              height: 19px;
            }
            div {
              display: flex;
              margin: 0px 0 10px 0;
              align-items: center;
            }
          }
        }
        .allJobs-section {
          width: 77%;
          .first-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .all-jobs {
              h3 {
                font-weight: 700;
                color: #25324b;
                margin: 3px 0;
              }
            }
            .sorted-by {
              display: flex;
              align-items: center;
              gap: 10px;
              span {
                select {
                  width: 135px;
                  outline: none;
                  border: none;
    
                  color: #25324b;
                  font-weight: 500;
                }
              }
             
             
            }
          }
          > .company-box {
            padding: 18px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            cursor: pointer;
            transition: .2s ease all;

            &:hover{
              box-shadow: 0 14px 40px rgba(30,10,58,.1);
              background-color: #efebeb;
            }
           
         
            h4 {
              font-weight: 700;
              color: #25324b;
              margin: 4px 0;
            }
           
            Button {
              padding: 6px 12px;
              font-weight: 600;
            }
          }
          .end-button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            margin: 30px 0 70px 0;
            Button {
              border: none;
              background-color: transparent;
              color: #515b6f;
              font-weight: 600;
              font-size: 15px;
              padding: 10px 20px;
            }
            .active {
              background-color: $nd-primary-color;
              color: #fff;
            }
          }
        }
      }
    
}
.setting-section {
  padding: 24px 32px;
  height: 205%;
  > section {
    > div {
      .company-logo {
        height: 125px;
        width: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #f8f8fd;
      }
    }
  }
  > .setting-header {
    padding-bottom: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 24px;
    > .heading {
      font-size: 27px;
      font-weight: 600;

      font-family: $font-family-inter;
      color: "#25324B";
    }
    > .setting-navbar {
      box-shadow: 0px -1px 0px 0px #d6ddeb inset;
      margin: 0;
      > .setting-nav {
        padding: 0;
       cursor: pointer;
       
        div {
          color: #7c8493;
          font-size: 16px;
          font-weight: 600;
         
          font-family: $font-family-inter;
          font-style: normal;
          &.active {
            color: #25324b;
            padding-bottom: 12px;
            border-bottom: 3px solid #1fa0ff;
            border-radius: 0 0 0 0 10px;
          }
        }
      }
    }
  }
  
}
.form-control {
  display: flex;
  padding: 8px 14px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d6ddeb;
  background: #fff;
  &::placeholder {
    color: #9aa0ad;
    font-family: $font-family-inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
}

.add-member-btn {
  display: flex;
  Button {
    display: flex;
    padding: 10px 16px;
    border: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    background: #1fa0ff;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      background-color: #1fa0ff;
    }
  }
}

.roomy-btn {
  display: flex;
  Button {
    display: flex;
    padding: 7px 13px;
    border: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #1fa0ff;
    border-radius: 3px;
    background: rgb(243, 239, 239);
    font-size: 20px;
    font-weight: 600;
    &:hover {
      background-color: black;
    }
  }
}

.grip-btn {
  display: flex;
  Button {
    display: flex;

    border: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #7c8493;
    background-color: transparent;
    font-size: 30px;
    font-weight: 800;
    &:hover {
      background-color: transparent;
      color: #7c8493;
    }
  }
}
.team-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  > .total-members {
    font-size: 20px;
    font-weight: 600;
    color: #202430;
  }
}

.member-box {
  width: 220px;
  height: 230px;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #d6ddeb;
  background: #fff;
  margin-bottom: 20px;
  > .member-profile {
    img {
      width: 70px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
  }
  > .member-social-link {
    display: flex;
  
    >i {
      font-size: 22px;
      margin: 0 10px;
    }
  }
  >.member-name{
    h5{
    padding: 0;
    margin: 0;
  }
  }
}

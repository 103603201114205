$primary-color: rgb(35, 216, 233);
$secondary-color: rgb(40, 163, 240);
$background-color: rgb(230, 248, 248);
$text-color-light: rgb(160, 155, 155);

$nd-primary-color: #1FA0FF;
$heading-color:"#25324b";
$text-color-dark: black;
$font-family-inter: 'InterVariable', sans-serif;
$font-family-epilogue: 'EpilogueVariable', serif;
$font-family-default: Sans-serif;
.employer-sidebar {
    height: 100vh;
    background: #F8F8FD;
    width: 20rem;
    padding-right: 1rem;

    .nd-logo {
        img {
            width:200px;
        }
    }

    .nav-item {

        .nav-link {
            padding: 15px 20px;
            color: #7C8493;
            font-weight: 500;
            border-radius: 0;
            font-size: 15px;
            border-left: 3px solid #F8F8FD;

            i {
                color: #7C8493;
                padding-right: 20px;
                font-size: 17px;
            }

            &.active {
                background-color: #E9EBFD;
                border-radius: 0;
                color: $nd-primary-color;
                border-left: 3px solid $nd-primary-color;
                transition: ease .2s;

                i {
                    color: #1FA0FF;
                }
            }
        }



    }

    .paragraph {
        font-size: 12px;
        color: #515B6F;
        font-family: $font-family-epilogue;
        font-weight: 400;
    }

    .heading {
        font-size: 15px;
        font-weight: 600;
        font-family: $font-family-epilogue;
        color: #25324B;
    }
}
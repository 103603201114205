.complete-signup-page{
    margin-top: 80px;
    margin-bottom: 50px;

    .form-label{
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #515B6F;
    }
    a{
        text-decoration: none;
        color: #1FA0FF;
    }


 
}
.profile-section {
  padding: 24px 32px;
  height: 420%;
  > .profile-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    > .company-icon {
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #f8f8fd;
    }
    > .right-brand {
      display: block;
      width: 85%;
      > .right-top-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > span {
          font-size: 40px;
          font-weight: 600;
          color: #25324b;
          font-family: $font-family-inter;
        }
        > .edit-btns {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 10px;
          Button {
            padding: 10px 16px;
            margin: 0;
            color: #1fa0ff;
            font-size: 15px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background-color: transparent;
            border-radius: 30px;
            border: 1px solid #ccccf5;
            &:nth-child(1) {
              border: none;
            }
          }
        }
      }
      > div {
                
        > .about-company-box {
            margin-top: 15px;
            display: flex;
            align-items: center;
            gap: 8px;
          > .about-icon {
            width: 40px;
            height: 40px;
            display: flex;
            font-size: 20px;
            align-items: center;
            color: #1fa0ff;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #d6ddeb;
            background: #fff;
          }
          >div{
            p{
              padding: 0;
              margin: 0;
            }
           h6{
            margin: 2px 0;
            
           }
          }
        }
      }
    }
  }
}


.head-quater-btn{
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $nd-primary-color;
  font-size: 14px;
  font-weight: 600;
  background-color:rgba(38, 164, 255, 0.10);
  border: none;
  border-radius: 30px;
}
.header {
  position: sticky;
  top: 0;
  z-index: 100;

  a {
    text-decoration: none;
    color: #212223;
  }

  .home-nav-bar {
    width: 100%;
    background-color: #fff;
    height: 75px;
    border-bottom: 1px solid #d9d6d6;
    .nav-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      .main-brand {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nav-right {
        width: 100%;
        padding: 0 90px 0 70px;

        a {
          color: #212223;
          font-size: 15px;
          font-weight: 500;
          padding: 0 15px;
        }

        .active {
          color: $nd-primary-color;
        }

        .navbar-collapse {
          .navbar-nav {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profile {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon-down {
                Button {
                  color: #515b6f;
                  padding: 0;
                  margin: -15px 0 0 0;
                }
              }

              .user-img {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .user-name {
                h6 {
                  font-weight: 600;
                  margin: 0;
                }

                p {
                  color: #515b6f;
                  font-size: 15px;
                  margin: 0;
                  padding: 0;
                }
              }
            }

            .nav-buttons {
              display: flex;
              justify-content: flex-end;
              gap: 15px;

              .btn1 {
                background-color: transparent;
                border-radius: 30px;
				padding: 8px 20px;
                border: none;

                color: $nd-primary-color;
                font-size: 15px;
                font-weight: 700;
				&:hover {
					background-color: $nd-primary-color;

				
						color: #fff;
				
				}
              }

              .divider-nav {
                width: 1px;
                background: #d6ddeb;
                opacity: 0.5;
              }

              .btn2 {
                padding: 8px 20px;
                background-color: $nd-primary-color;
                border-radius: 30px;
                border: none;

               
                 
                  color: #fff;
                  font-size: 15px;
                  font-weight: 700;
               
              }
            }
          }
        }
      }
    }
  }
}

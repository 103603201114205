.employer-topbar {
    box-shadow: 0px -1px 0px 0px #D6DDEB inset;

    .left-brand {
        display: flex;
        font-family: $font-family-epilogue;
    }

    .left-brand .company-para {
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        color: #515B6F;
    }

    .left-brand .heading,
    Button {
        font-size: 19px;
        font-weight: 600;
        font-style: normal;
        color: #25324B;
        i{
            font-size: 17px;
            margin-top: 4px;
        }
    }

    .right-postjob {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
        font-family: $font-family-epilogue;

        a {
            text-decoration: none;
            color: #fff;
        }

        .notification {
            font-size: 20px;
            color: #25324B;
            background-color: transparent;
            padding: 0;
            border: none;
        }

        .postjob-btn {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 30px;
            background-color: $nd-primary-color;
            border: none;

            span,
            i {
                color: #fff;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}
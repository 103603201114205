.self-analysis-container {
  margin-bottom: 72px;
  .first-section-wish {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    margin: 44px 0;
    border-radius: 12px;
    background: #e8fafa;
    p {
      padding: 0;
      margin: 0;
    }
    .calender {
      background-color: #fff;
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid #d6ddeb;
      font-size: 15px;
      cursor: pointer;
      color: #25324b;
      i {
        margin: 0 0px 0 10px;
        color: #4640de;
      }
    }
  }

  .job-statics-box {
    width: 65%;
   
    > div {
      > .year-btn {
        display: flex;
        background-color: #e9ebfd;
        padding: 8px;
        gap: 2px;
        > .active {
          background-color: white;
        }
        button {
          border: none;
          border-radius: 0;
          padding: 4px 15px;
          background-color: transparent;
          color: $nd-primary-color;
          font-weight: 500;
        }
      }
    }
    .job-statistic-navbar {
      padding: 0px 20px;

      box-shadow: 0px -1px 0px 0px #d6ddeb inset;
      > .nav-job {
        font-size: 18px;
        color: #25324b;
        font-weight: 600;

        > .active {
          border-bottom: 4px solid $nd-primary-color;
         
        }
        .nav-item {
          padding-bottom: 8px;
        }
      }
    }
    .dount-chat-section {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      
      margin: 80px 25px 30px 25px;

      .skill-data {
        width: 30%;
        padding: 15px 15px 50px 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .arrow-icon {
          color: #1fa0ff;
        }
      }
    }
  }
  .open-job-applicant-summary {
    width: 32%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    div{
      .big-number {
        font-size: 65px;
        padding: 0;
        margin: 0;
        font-weight: 700;
        }
    }
    .open-job-box {
     
      padding: 24px 24px 24px 24px;
    
    }
    .applicant-summary-box {
     
      padding: 24px 24px 24px 24px;
     
      .review-part-box{
        width: 40%;
        display: flex;
        align-items: center;
        gap: 8px;
        .box-review{
          width: 22px;
          height: 22px;
          border-radius: 5px;
          background-color: #1FA0FF;
        }
        .box-hired{
          width: 22px;
          height: 22px;
          border-radius: 5px;
          background-color: #16A799;
        }
        .box-shortlisted{
          width: 22px;
          height: 22px;
          border-radius: 5px;
          background-color: #FFB836;
        }
        .box-rejected{
          width: 22px;
          height: 22px;
          border-radius: 5px;
          background-color: #FF6550;
        }
        .box-interview{
          width: 22px;
          height: 22px;
          border-radius: 5px;
          background-color: #26CBFF;
        }
        
      }

     
    }
  }

  .bottom-chats-section {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    padding: 24px;
    .skill-analysis-part {
      width: 30%;
      padding: 24px;
     
    }
    .feature-assessment-part {
      width: 65%;
      padding:50px 24px 24px 24px;

      .up-skill-section {
       
       width: 35%;
        display: flex;
        flex-direction: column;

        gap: 20px;
        .up-skill {
          padding: 20px;
        }
       
      }
    }
  }
}

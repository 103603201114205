.job-detail-section {
	a {
		text-decoration: none;
	}

	>.first-container {
		padding: 70px 0;
		background-color: rgba(127, 255, 212, 0.185);

		>.container {
			display: flex;
			flex-direction: column;
			gap: 35px;

			.pages-links {
				font-size: 15px;

				a {
					color: #7c8493;
				}
			}

			.job-title-box {
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 24px;

				.left-description {
					display: flex;
					// align-items: center;
					gap: 20px;

					h3 {
						font-weight: 800;
						color: #25324b;
						margin: 0px 0 8px 0;
					}
				}

				.apply-side {
					display: flex;
					gap: 30px;
					align-items: center;

					.share-icon {
						color: #bfc4ce;
						font-size: 28px;
					}

					Button {
						padding: 12px 50px;

						a {
							font-weight: 700;
							color: #fff;
						}
					}
				}
			}
		}
	}

	h3 {
		margin: 20px 0;
		font-weight: 700;
	}

	.text-light-black {
		color: rgba(54, 53, 53, 0.936);
	}

	.text-gray {
		color: rgb(118, 116, 116);
	}

	.bg-light-gray {
		background-color: rgba(243, 234, 234, 0.655);
	}

	.bg-light-green {
		background-color: rgba(215, 239, 215, 0.642);
	}

	.bg-light-warning {
		background-color: rgba(239, 234, 211, 0.621);
	}

	.para {
		i {
			color: #56cdad;
		}
	}

	.m-left {
		margin-left: 95px;
	}

	.fourth-cont Button:hover {
		color: black;
	}

	.small-heading {
		font-size: 22px;
		font-weight: 600;
		display: block;
	}

	.p-right {
		padding-right: 40px;
	}

	.main_link {
		display: flex;
		font-size: 18px;
		margin-top: 40px;
	}

	.active {
		color: black;
	}

	.m-top {
		margin-top: 23%;
	}

	.about-company {
		margin-top: 40px;
		margin-bottom: 50px;
		background-color: white;
		padding: 15px 20px 25px 5px;
		display: flex;
		justify-content: space-between;
		border: 2px solid rgb(234, 228, 228);
	}

	hr {
		border: 1px solid rgb(143, 139, 139);
	}

	.py {
		padding: 15px 16px;
	}

	.brand_name {
		display: flex;
		margin-left: -15px;
	}

	.second-section {
		width: 100%;
		margin-top: 70px;
		margin-bottom: 70px;
	}

	.green-half {
		width: 40%;
		height: 8px;
		background-color: rgb(32, 159, 76);
	}

	.gray-half {
		width: 58%;
		height: 8px;

		background-color: rgb(207, 212, 211);
	}

	.fourth-cont {
		padding: 70px 0;
	}

	.all-btn Button:hover {
		color: black;
	}

	.border-bottom-black {
		border-bottom: 5px solid rgb(32, 32, 32);
	}

	.all-btn {
		padding: 12px 15px;
		border: 2px solid rgb(227, 217, 217);
		border-top: none;
	}


}
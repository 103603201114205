body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  // overflow: hidden;
  color: #212223;
  font-family: $font-family-inter;
}

a {
  text-decoration: none;
}

// heading sizes
h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 1rem;
}

// for paragraph
.para {
  color: #56595b;
  font-size: 15px;
}

.big-para {
  font-size: 17px;
  color: #56595b;
}

.nd-para {
  font-size: 14px;
  color: #25324b;
}

// for horizonatal line with 24px top bottom space
#horizontal-line {
  margin: 24px auto;
  height: 1px;
  background-color: #d6ddeb;
}

.primary-btn {
  display: flex;
  padding: 10px 24px;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: $nd-primary-color;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: $nd-primary-color;
  }
}

.small-btn {
  >Button {
    background-color: transparent;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #d6ddeb;
    font-size: 15px;
    color: $nd-primary-color;
  }
}

.normal-btn {
  Button {
    background: transparent;
    border: 1px solid $nd-primary-color;
    color: $nd-primary-color;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 0;

    &:hover {
      background-color: #fff;
      color: $nd-primary-color;
      border: 1px solid $nd-primary-color;
    }

    i {
      font-size: 17px;
    }
  }
}

.simple-btn {
  Button {
    border: none;
    align-items: center;
    gap: 12px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 0;

    &:hover {
      background-color: #fff;
      color: $nd-primary-color;
    }

    span {
      color: $nd-primary-color;
    }

    i {
      color: $nd-primary-color;
      font-size: 17px;
    }
  }
}

.full-time-btn {
  border-radius: 30px;
  padding: 4px 10px;
  border: none;

  color: #56cdad;
  font-size: 13px;
  font-weight: 600;
  background-color: rgba(86, 205, 173, 0.1);
}

.gray-bg-btn {
  // background: #e6e6ea;
  background: #f8f8fd;
  border-radius: 0;

  display: flex;
  padding: 8px 16px;
  align-items: center;
  color: $nd-primary-color;
  font-weight: 500;
}

.marketing-btn {
  border-radius: 30px;
  padding: 5px 10px;

  color: #ffb836;
  font-size: 13px;
  border: 1px solid #ffb836;
  font-weight: 600;
  background-color: transparent;
}

.business-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 30px;
  color: #56cdad;
  background-color: transparent;
  font-size: 13px;
  border: 1px solid #56cdad;
}

.design-btn {
  border-radius: 30px;
  display: inline-block;
  padding: 4px 10px;
  border: 1px solid $nd-primary-color;
  color: $nd-primary-color;
  font-size: 13px;
  font-weight: 600;
  background-color: transparent;
}

.technology-btn {
  border-radius: 30px;
  padding: 4px 10px;
  border: none;

  color: #ff6550;
  font-size: 13px;
  font-weight: 600;
  background-color: rgba(255, 101, 80, 0.1);
}

// for required skill dommy buttons
.req-skill-btn {
  background: rgba(230, 228, 224, 0.717);
  color: #1FA0FF;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;

}

// for benifit box
.primary-border {
  border: 1px solid #d6ddeb;
  border-radius: 6px;
}

.binifit-box {
  display: flex;
  width: 250px;
  padding: 16px 24px;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;

  >div {
    .health-icon {
      font-size: 30px;
      font-weight: 400;
      color: #1fa0ff;
    }

    .close-btn {
      font-size: 24px;
      color: #25324b;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  >.health-heading {
    font-size: 18px;
    font-weight: 600;
    color: #25324b;
  }

  >.health-para {
    color: #515b6f;
    font-family: $font-family-inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
}

.job-box {
  padding: 16px 30px;
  margin-top: 15px;
  display: flex;
  gap: 20px;
  width: auto;

  >div {
    div {
      display: flex;

      align-items: center;

      >.dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #515b6f;
        margin: 0 10px;
      }
    }
  }
}

.divider-right {
  font-size: 20px;
  height: 30px;
  width: 1px;
  opacity: 0.7;
  background-color: #d6ddeb;
}

// for modal css
.modal-content {
  border-radius: 0;
  margin-top: 80px;
  border-radius: 10px;
  background: #fff;
  outline: none;
  padding: 16px 32px;

  // width: 650px;
  >.modal-header {
    border-bottom: none;
    display: flex;
    align-items: flex-start;

    >.btn-close {
      color: #25324b;
      opacity: 0.7;
      font-size: 15px;
    }

    >.modal-title {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .brand_name {
    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.attach-btn {
  // &:hover {
  //   background-color: transparent;
  //   border: 2px dashed $nd-primary-color;
  // }

  Button {
    background-color: transparent;
    padding: 16px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 2px dashed $nd-primary-color;

    i {
      color: $nd-primary-color;
    }

    span {
      color: #515b6f;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

// for file upload css

.company-file {
  display: flex;
  padding: 16px 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
  cursor: pointer;
  border-radius: 8px;
  // border: 2px dashed #1fa0ff;
  // background: #f8f8fd;

  .file-logo {
    width: 150px; /* Fixed width */
    height: 150px; /* Fixed height */
    border: 1px solid #ccc; /* Optional: adds a border around the logo container */
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures the image does not overflow the container */
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* This will ensure the logo keeps its aspect ratio */
  }

  >.file-logo {
    color: #1fa0ff;
    font-size: 30px;
  }
}

// for searching buttons

div {
  >.search-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // flex-wrap: wrap;
    gap: 16px;
    margin: 16px 0;
    background-color: #fff;
    border-radius: 20px;
    padding: 16px;

    .search-job {
      padding: 12px 16px;
      display: flex;
     
      position: relative;
      gap: 10px;

      align-items: center;
      border: 1px solid #b1abab;
      border-radius: 30px;

      i {
        color: #d9d9d9;
        font-size: 15px;
      }

      input,
      .css-13cymwt-control {
        outline: none;
        border: none;
        font-size: 15px;

        &::placeholder {
          color: #212223;
          opacity: 1;
          font-size: 15px;
        }
      }

    }

    // .search-location {
    //   padding: 5px 20px;
    //   display: flex;
    //   gap: 10px;
    //   // width: 350px;

    //   align-items: center;
    //   border: 1px solid #6c3232;
    //   border-radius: 30px;
    //   i {
    //     color: #d9d9d9;
    //     font-size: 16px;
    //   }
    //   > select {
    //     width: 200px;
    //     outline: none;
    //     border: none;
    //     font-size: 15px;
    //   }
    // }
    .search-submit-btn {
      display: flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #212223;
      border: none;
      border-radius: 30px;
      background: linear-gradient(256deg,
          #1fa0ff 5.66%,
          #12dafb 52.9%,
          #a7fdcc 96.36%);

     
     
     
    }
  }
}

//  for no Result found 
.no-result-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  gap: 12px;

  h1 {
    font-size: 50px;
    color: #25324b;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    color: #1fa0ff;
    text-shadow: 0.5px 1px #12dafb;
  }
}

// for similiar jobs section
.similiar-jobs {
  width: 100%;
  background-color: rgba(242, 244, 246, 0.776);
  clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 99%, 0 8%);
  padding: 55px 0 65px 0;

  .special-box {
    width: 49%;
    padding: 20px 0px 25px 20px;
    display: flex;
    border-radius: 7px;
    background-color: white;
    margin: 9px 0;
  }
}




// for big buttons specipic classes



.big-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;

  >.review-button {

    padding: 18px;
    background-color: #1fa0ff;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 6px;
    gap: 15px;

    >.big-number {
      font-size: 44px;
      font-weight: 600;
    }

    h4 {
      color: #fff;
    }

    p {
      font-size: 18px;
      padding: 0;
      margin: 0;
    }

    i {
      font-size: 16px;
      padding: 0;
      margin: 0 25px;
    }
  }

  >.schedule-button {

    padding: 18px;
    background-color: #16a799;
    border: none;
    display: flex;

    align-items: center;

    border-radius: 6px;
    gap: 15px;

    >.big-number {
      font-size: 44px;
      font-weight: 600;
    }

    h4 {
      color: #fff;
    }

    p {
      font-size: 18px;
      padding: 0;
      margin: 0;
    }

    i {
      font-size: 16px;
      padding: 0;
      margin: 0 35px;
    }
  }

  >.interview-button {

    padding: 18px;
    background-color: #26a4ff;
    border: none;
    border-radius: 6px;
    display: flex;

    align-items: center;

    gap: 15px;

    >.big-number {
      font-size: 44px;
      font-weight: 600;
    }

    p {
      font-size: 18px;
      padding: 0;
      margin: 0;
    }

    i {
      font-size: 16px;
      padding: 0;
      margin: 0 35px;
    }
  }
}

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;

}

.ql-container.ql-snow {
  border-radius: 0 0 8px 8px;
  font-size: 15px;
  color: #A8ADB7;


  .ql-editor {
    height: 120px;


  }
}
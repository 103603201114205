// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
//   font-family: $font-family-inter;
// }

.suceess-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .success-box {
   
   width: 420px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 48px 30px;
    border-radius: 15px;
    background:  #fff;
    box-shadow: 10px 44px 65px 0px rgba(176, 183, 195, 0.256);

    .check-arrow {
      text-align: center;
      img{
        width: 125px;
      }
    }
    h4 {
    
      text-align: center;
      font-size: 24px;
     font-family: $font-family-inter;
      font-weight: 700;
    }
    .para {
    
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
     
    }
    .mail-btn {
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-weight: 700;
      align-self: stretch;
      border: none;
      background: linear-gradient(
        256deg,
        #1fa0ff 5.66%,
        #12dafb 52.9%,
        #a7fdcc 96.36%
      );
    }
  }
}

@media only screen and (max-width: 976px) {
  .suceess-section {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.policies-section{
    .first-row{
        padding: 80px 0px ;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(127, 255, 212, 0.185);
        h1{
            font-size: 48px;
        
        }
    }
    .container{
        padding: 50px;
        .above-section{
            margin-bottom: 50px;
            p{
                margin: 25px 0;
                font-size: 15px;
                color: #212223;
            }
        }
        .list-section{
            margin-bottom: 100px;
            ol{
                
                
              
                li{
                  
                        font-size: 20px;
                        font-weight: 700;
                        font-family: $font-family-inter;
                     
                   p{
                    font-size: 16px;
                    font-weight: 400;
                    font-family: $font-family-inter;
                    i{
                        color: #D9D9D9;
                    }
                   }
                }
            }

            .fa-circle-check {
                color: #56cdad;
            }
        }
    }
}
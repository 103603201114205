.company-profile-section{
    a {
        text-decoration: none;
      }
    
      > .first-container {
        padding: 30px 0;
        background-color: rgba(127, 255, 212, 0.185);
        > .container {
          display: flex;
          flex-direction: column;
          gap: 35px;
          .pages-links {
            font-size: 15px;
           a{
            color: #7c8493;
           }
          }
          .profile-header {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            h6{
                margin: 1px 0;
            }
            > .company-icon {
              width: 160px;
              height: 160px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              background: #f8f8fd;
            }
            > .right-brand {
              display: flex;
              flex-direction: column;
              gap: 10px;
              a{
                color: #4640DE;
                font-weight: 600;
              }
           
             
             
              > div {
                
                > .about-company-box {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                  > .about-icon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    font-size: 20px;
                    align-items: center;
                    color: #1fa0ff;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid #d6ddeb;
                    background: #fff;
                  }
                  >div{
                    p{
                      padding: 0;
                      margin: 0;
                    }
                   h6{
                    margin: 2px 0;
                    
                   }
                  }
                }
              }
            }
          }
         
        }
      }
}
// *{
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
//     font-family: $font-family-inter;
// }

.employer-onboard {
    display: flex;
    overflow: hidden;
    max-height: 100vh;
}

// .side-bar-left {
//     max-height: 100vh;
// }

// .side-bar-left .nav-item .active {
//     background-color: #E9EBFD;
//     border-radius: 0;
//     color: #1FA0FF;
//     font-weight: 600;
//     border-left: 3px solid #1FA0FF;

//     i {
//         color: #1FA0FF;
//     }
// }


// .side-bar-left .nav-item .nav-link {
//     padding: 15px 20px;
// }

// .side-bar-left .nav-link {
//     color: #7C8493;
//     font-weight: 500;

//     i {
//         color: #7C8493;
//     }
// }

// .side-bar-left .paragraph {
//     font-size: 15px;
//     color: #515B6F;
//     font-family: $font-family-epilogue;
//     font-weight: 400;
// }

// .side-bar-left .heading {
//     font-size: 18px;
//     font-weight: 600;
//     font-family: $font-family-epilogue;
//     color: #25324B;
// }

// side right bar css
.side-bar-right {
    width: 100%;
}

.page-content {
    height: calc(100% - 83px);
    overflow-y: auto;
}

// topbar css

// .fixed-topbar {
//     width: 100%;
//     padding: 16px 32px;
//     position: relative;
// }

// .side-bar-right .left-brand {
//     display: flex;
//     font-family: $font-family-epilogue;
// }

// .side-bar-right .left-brand .company-para {
//     font-size: 16px;
//     font-weight: 400;
//     font-style: normal;
//     color: #515B6F;
// }


// .side-bar-right .left-brand .heading,
// Button {
//     font-size: 20px;
//     font-weight: 600;
//     font-style: normal;
//     color: #25324B;
// }


// .side-bar-right .right-postjob {
//     display: flex;
//     gap: 40px;
//     justify-content: center;
//     align-items: center;
//     font-family: $font-family-epilogue;

//     a {
//         text-decoration: none;
//         color: #fff;
//     }
// }


// .side-bar-right .right-postjob .well-icon {
//     font-size: 24px;
//     color: #25324B;
//     background-color: transparent;
//     padding: 0;
//     border: none;
// }


// .side-bar-right .right-postjob .postjob-btn {
//     display: flex;
//     padding: 12px 24px;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;
//     border-radius: 30px;
//     background-color: #1FA0FF;
//     border: none;

//     span,
//     i {
//         color: #fff;
//         font-size: 16px;
//         font-weight: 700;
//     }
// }
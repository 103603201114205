.homepage-section {
  // width: 100%;
  // padding-bottom: 100px;
  position: relative;

  // padding: 0 120px;
  a {
    text-decoration: none;
    color: #212223;
  }

  .container {
    > .hero-section {
      display: flex;
      justify-content: space-between;

      > .left-hero-section {
        width: 62%;
        padding: 120px 0px 0px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .big-paragraph {
          color: #212223;
          font-family: $font-family-epilogue;
          font-size: 55px;
          font-style: normal;
          position: relative;
          // z-index: 100;
          font-weight: 700;
          // background-color: #12dafb;
          // box-shadow:10px 0px 10px #12dafb;
          line-height: 70px;
          letter-spacing: 0.3px;
          padding-right: 180px;
          // .fading-box {
          //   width: 150px;
          //   height: 150px;
          //   position: absolute;

          //   border-radius: 100px;
          //   opacity: 0.5;
          //   top: 40px;
          //   right: 180px;
          //   -webkit-box-shadow: -20px -2px 154px 50px #12dafb;
          //   -moz-box-shadow: -20px -2px 154px 50px #12dafb;
          //   box-shadow: -10px -1px 154px 50px #12dafb;
          // }
          .gradient-circle {
            width: 100%;
            height: 200%;
            background-image: radial-gradient(
              circle closest-side at center,
              rgba(18, 218, 251, 0.4) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            top: 60%;
            left: 60%;
            opacity: 0.9;
            transform: translate(-50%, -50%);
            z-index: 0;
          }
        }

        .para {
          color: #212223;
          font-family: $font-family-inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;

          letter-spacing: 0.08px;
        }

        // >.search-btns {
        //   background-color: #fff;
        //   border-radius: 15px;
        //   padding: 16px;

        //   .search-job {
        //     padding: 5px 20px;
        //     position: relative;
        //     display: flex;
        //     gap: 10px;
        //     justify-content: center;
        //     align-items: center;
        //     border: 1px solid #d9d9d9;
        //     border-radius: 25px;

        //     i {
        //       color: #d9d9d9;
        //       font-size: 16px;
        //     }

        //     input,
        //     .css-13cymwt-control {
        //       outline: none;
        //       border: none;
        //       font-size: 15px;
        //     }

        //     &::placeholder {
        //       color: #212223;
        //       opacity: 1;
        //       font-size: 15px;
        //     }
        //   }

        //   .search-location {
        //     padding: 5px 20px;
        //     display: flex;
        //     gap: 10px;
        //     justify-content: center;
        //     align-items: center;
        //     border: 1px solid #d9d9d9;
        //     border-radius: 25px;

        //     i {
        //       color: #d9d9d9;
        //       font-size: 16px;
        //     }

        //     >select,
        //     .css-13cymwt-control {
        //       width: 200px;
        //       outline: none;
        //       border: none;
        //       font-size: 15px;
        //     }
        //   }

        //   .search-submit-btn {
        //     display: flex;
        //     padding: 4px 20px;
        //     justify-content: center;
        //     align-items: center;
        //     gap: 8px;
        //     border-radius: 32px;
        //     background: linear-gradient(256deg,
        //         #1fa0ff 5.66%,
        //         #12dafb 52.9%,
        //         #a7fdcc 96.36%);

        //     Button {
        //       background-color: transparent;
        //       border: none;
        //       color: #212223;
        //     }
        //   }
        // }
      }

      .right-hero-section {
        width: 26%;
        // position: relative;
        height: 600px;
        padding-top: 100px;
        background: url("../../img/image\ 9.png"),
          linear-gradient(271deg, #19c4d2 2.18%, #22d9aa 88.65%);
        border-radius: 0px 0px 200px 200px;

        > .jobpost-box {
          position: absolute;
          cursor: pointer;
          margin-top: 40px;
          margin-left: -50px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: center;
          align-items: center;
          border-radius: 100px 100px 20px 20px;
          background: linear-gradient(
            256deg,
            #1fa0ff 5.66%,
            #12dafb 52.9%,
            #a7fdcc 96.36%
          );
          box-shadow: 56px 56px 80px 0px rgba(2, 14, 35, 0.24);
          backdrop-filter: blur(10px);

          i {
            font-size: 20px;
            color: #fff;
          }

          h5 {
            font-weight: 500;
            margin: 0;
            color: #212223;
          }

          p {
            color: #212223;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    > .category-section {
      margin: 100px 0 0 0;

      > div {
        margin-top: 30px;

        > .job-cate-box {
          // background: #f9f9f9;
          // border-radius: 7px;
          // padding: 30px 60px 50px 40px;
          // cursor: pointer;
          background: #f9f9f9;
          border-radius: 7px;
          padding: 50px 25px;
          cursor: pointer;
          width: 23.7%;

          > div {
            i {
              color: #12dafb;
              font-size: 30px;
              margin-bottom: 20px;
            }
          }

          &:hover {
            clip-path: polygon(0 13%, 11% 0, 100% 0, 100% 100%, 0 100%);
            background: linear-gradient(271deg, #19c4d2 2.18%, #22d9aa 88.65%);

            div {
              i {
                color: white;
              }
            }

            h5,
            .para,
            i {
              color: white;
            }
          }
        }

        // >.active-box {
        // 	clip-path: polygon(0 13%, 11% 0, 100% 0, 100% 100%, 0 100%);
        // 	background: linear-gradient(271deg, #19c4d2 2.18%, #22d9aa 88.65%);

        // 	div {
        // 		i {
        // 			color: white;
        // 		}
        // 	}

        // 	h5,
        // 	.para,
        // 	i {
        // 		color: white;
        // 	}
        // }
      }
    }

    > .posting-job-poster {
      display: flex;

      margin: 80px 0 0 0;

      gap: 40px;
      justify-content: space-between;
      background-color: $nd-primary-color;
      padding: 70px 70px 0px 70px;
      border-radius: 25px;
      clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%, 0 18%);

      > .start-post-job {
        padding: 30px 20px;
        line-height: 3;
        gap: 20px;

        h1 {
          color: #fff;
          font-weight: 600;
        }

        p {
          color: #fff;
          font-size: 15px;
        }

        Button {
          border: none;

          background-color: #fff;

          padding: 12px 24px;
          border-radius: 30px;
          align-items: center;

          a {
            color: $nd-primary-color;
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
    }

    > .findwork-section {
      margin-top: 150px;
      position: relative;
      z-index: 1;
      .gradient-circle {
        position: absolute;
        top: 10%;
       left: 65%;
        width: 80%;
        height: 100%;

        background-image: radial-gradient(
          circle closest-side at center,
          rgba(18, 218, 251, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%
        );

        opacity: 0.8;
        transform: translate(-50%, -50%);
        z-index: 0;
      }

      .find-work-text {
        z-index: 1;
        h1 {
          font-weight: 500;
          z-index: 1;
          font-size: 48px;
        }

        p {
          width: 35%;
          font-size: 16px;
          text-align: center;
          z-index: 1;
        }
       
      }

      .find-content {
        display: flex;

        justify-content: space-between;
        margin-top: 30px;

        > .find-opportunity {
          position: relative;
          width: 38%;
          height: 345px;
          padding: 32px 40px;
          border-radius: 24px;
          background: linear-gradient(271deg, #19c4d2 2.18%, #22d9aa 88.65%);

          > .icon-user {
          img{
            width: 45px;
          }
          }

          h4 {
            color: #212223;
            font-size: 22px;
            font-weight: 550;
            margin-top: 10px;
            line-height: 30px;
          }
          div{
           
              .black-dot {
                padding: 0;
                margin: 0;
                width: 8px;
                height: 8px;
                background-color: #212223;
                border-radius: 10px;
              }
              .active {
                width: 16px;
                height: 8px;
                background-color: #fff;
                border-radius: 10px;
              }

           
          }

        

        

          .right-arrow-btn {
            background-color: #12dafb;
            width: 44px;
            height: 44px;
            right: -20px;
            top: 40%;
            cursor: pointer;
            box-shadow: 12px 12px 24px 0px rgba(2, 22, 56, 0.24);
            position: absolute;
            border: 8px solid white;
            border-radius: 50%;
            display: flex;
            color: #fff;
            align-items: center;
            justify-content: center;
          }

          .left-arrow-btn {
            background-color: #12dafb;
            width: 44px;
            height: 44px;
            left: -20px;
            top: 40%;
            cursor: pointer;
            box-shadow: 12px 12px 24px 0px rgba(2, 22, 56, 0.24);
            position: absolute;
            border: 8px solid white;
            border-radius: 50%;
            display: flex;
            color: #fff;
            align-items: center;
            justify-content: center;
          }

          Button {
            background-color: #212223;
            color: #fff;
            border: none;
            border-radius: 30px;
            padding: 16px 30px;
            font-size: 16px;
            font-weight: 600;
          }
          // for cerate a stroke
          .line-with-circles {
            position: relative;
            width: 100%;
            height: 1px;

            margin: 40px auto;
            background-color: #575757;
            /* Color of the line */
          }

          .line-with-circles::before,
          .line-with-circles::after {
            content: "";
            position: absolute;
            width: 10px;
            /* Diameter of circles */
            height: 10px;
            /* Diameter of circles */
            border-radius: 50%;
            background-color: #000;
            /* Color of the circles */
          }

          .line-with-circles::before {
            left: -5px;
            /* Half of the width of the circle */
            top: -5px;
            /* Half of the height of the circle */
          }

          .line-with-circles::after {
            right: -5px;
            /* Half of the width of the circle */
            top: -5px;
            /* Half of the height of the circle */
          }
        }

        .right-image {
          width: 57%;
          height: 345px;
          position: relative;
          overflow: hidden;
          border-radius: 24px;
        }
      }
    }

    > .client-review-part {
      margin-top: 100px;
      position: relative;
      z-index: 1;

      .gradient-circle {
        position: absolute;
        width: 450px;
        height: 450px;
        background-image: radial-gradient(
          circle closest-side at center,
          rgba(18, 218, 251, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%
        );

        top: 20%;
        // left: 0;
        right: -30%;
   
        opacity: 1;
        transform: translate(-50%, -50%);
        
      }
      div {
        h1 {
          font-weight: 500;
        }

        p {
          width: 35%;
          font-size: 15px;
          text-align: center;
        }
      }

      div {
        > .client-review-box {
          padding: 32px 50px;

          display: flex;
          gap: 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          background: #f9f9fb;

          // for cerate a stroke
          .line-with-circles {
            position: relative;
            width: 70%;
            height: 1px;
            margin: auto;
            background-color: #575757;
            /* Color of the line */
          }

          .line-with-circles::before,
          .line-with-circles::after {
            content: "";
            position: absolute;
            width: 10px;
            /* Diameter of circles */
            height: 10px;
            /* Diameter of circles */
            border-radius: 50%;
            background-color: #000;
            /* Color of the circles */
          }

          .line-with-circles::before {
            left: -5px;
            /* Half of the width of the circle */
            top: -5px;
            /* Half of the height of the circle */
          }

          .line-with-circles::after {
            right: -5px;
            /* Half of the width of the circle */
            top: -5px;
            /* Half of the height of the circle */
          }

          h6 {
            color: #000000;
            margin: 4px 0;
          }

          .para {
            font-size: 14px;
            color: #000000;
            font-weight: 400;
          }

          h4 {
            font-size: 22px;
            font-weight: 500;
            margin-top: 0px;
          }
        }
      }
    }
  }

  > .footer-part {
    margin-top: 70px;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0px;
    background: linear-gradient(
      256deg,
      #1fa0ff 5.66%,
      #12dafb 52.9%,
      #a7fdcc 96.36%
    );
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .footer-text {
        width: 45%;

        h1 {
          font-size: 48px;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      Button {
        background-color: #000000;
        font-size: 16px;
        font-weight: 600;
        border: none;
        border-radius: 30px;
        padding: 16px 67px;
        // width: 220px;
      }
    }
  }
}

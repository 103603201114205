// *{
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
//     color: #212223;
//     font-family: $font-family-inter;
// }

.logo {
    margin-left: 95px;
}
.logo img{
    width: 200px;
    margin-top: 25px;
    
}
.model-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.login .reduced-image {
    width: 75%;
    height: auto;
}

.signup .reduced-image {
    width: 75%;
    height: auto;
}

.review-box {
    height: 270px;
    width: 440px;
    background-color: white;
    padding: 18px 29px;
    position: absolute;
    right: 80px;
    font-family: $font-family-inter;
    bottom: 24px;

}

.circle-image img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    right: 30px;
    border: 12px solid white;
    background: #4640DE;
}

.review-box .client {
    font-size: 24px;
    font-weight: 600;
    color: rgb(150, 142, 142);
    padding-bottom: 5px;
}

.review-box .about-client {
    font-size: 24px;
    font-weight: 400;
    color: rgb(176, 170, 170);
}

.review-box .description {
    display: flex;
    justify-content: space-between;
}

.review-box .description .quote {
    font-size: 90px;
    font-weight: 700;
    font-family: math;
    background: linear-gradient(to right, #A7FDCC, #1FA0FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: inline;
}

.review-box .description .review {
    padding: 15px 0px 10px 14px;
    font-size: 24px;
    font-style:normal;
    font-weight: 600;
}

.login_section {
    // width: 55%;
    // max-height: 100vh;
    padding: 16px 190px 21.5px 190px;
    position: relative;
    // font-size: 16px;
}

.form-section {
    height: 100vh;
    overflow-y: auto;
}

.login_section Button {
    border-radius: 35px;
}


.type-switch-btn {
    border: none;
    font-weight: 550;
    font-size: 15px;
    color: #1FA0FF;
    background-color: transparent;

    &.active {
        background-color: rgba(216, 223, 235, 0.858) !important;
        color: #1FA0FF;
    }
}

// .btn-1 {
//     color: rgb(40, 163, 240);
//     background-color: rgb(224, 226, 229);

// }

// .btn-2 {
//     color: rgb(40, 163, 240);
//     background-color: transparent;
//     border: none;
//     font-weight: 550;
//     font-size: 15px;
// }


.login_section .heading {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}
.login_section a{
    text-decoration: none;
}
.left-border {
    width: 32%;
    height: 1px;
    background-color: rgb(225, 228, 230);
    margin-top: 10px;
}

.right-border {
    width: 32%;
    height: 1px;
    background-color: rgb(225, 228, 230);
    margin-top: 10px;
}

.login_section .paragraph {
    font-size: 15px;
    color: rgb(155, 151, 151);
}

.login_section input {
    border: 2px solid rgb(244, 239, 239);
    padding: 10px 20px;
    outline: none;
}

.login_section a:hover {
    color: red;
}

input::placeholder {
    color: rgb(227, 220, 220);
    font-size: 16px;
}

input.largerCheckbox {
    width: 20px;
    height: 20px;
}
.forget-btn{
    border: none;
    padding: 0;
    margin: 0;
    color: #1FA0FF;
    background: none;
}

.login_button {
    font-size: 15px;
    color: white;
    font-weight: 550;
    padding: 15px 0;
    margin-top: 15px;
    background: linear-gradient(to right, #A7FDCC,#12DAFB,#1FA0FF);
    // background-image: linear-gradient(to right, rgb(190, 223, 177), rgb(35, 216, 233), rgb(35, 216, 233));
    border: none;
}

@media only screen and (max-width:476px) {
    .left {
        display: none;
    }

    .login_section {
        padding: 20px 15px;
        position: relative;
    }

    .login_section .heading {
        font-size: 18px;
        font-weight: 600;
    }

    // .left-border {
    //     width: 15%;
    //     height: 1px;
    //     background-color: rgb(225, 228, 230);
    //     margin-top: 10px;
    // }

    // .right-border {
    //     width: 15%;
    //     height: 1px;
    //     background-color: rgb(225, 228, 230);
    //     margin-top: 10px;
    // }
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.divider-line {
    flex: 1;
    height: 1px;
    background-color: #000;
    margin: 0 10px;
    background-color: rgb(225, 228, 230);
}

.divider-text {
    white-space: nowrap;
    padding: 0 10px;
    color: #b0b0b0;
}


@media only screen and (min-width:477px) and (max-width:976px) {
    .left {
        display: none;
    }

    .login_section {
        min-height: 100vh;
        width: 100vw;
        padding: 40px 80px 0 80px;
        position: relative;
    }

    .login_section .heading {
        font-size: 30px;
        font-weight: 600;
    }

}


.error {
    color: red;
    font-size: 13px;
}


@media only screen and (min-width:977px) and (max-width:1255px) {
    .left {
        display: block;
    }



.review-box {
    height: 220px;
    width: 300px;
    background-color: white;
    padding: 10px 20px;
    position: absolute;
    right: 50px;
    font-family: "EpilogueItalic";
    bottom: 10px;

}

.circle-image img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: absolute;
    top: -40px;
    right: 30px;
    border: 10px solid white;
    background: #4640DE;
}

.review-box .client {
    font-size: 19px;
    font-weight: 600;
    color: rgb(150, 142, 142);
    padding-bottom: 5px;
}

.review-box .about-client {
    font-size: 19px;
    font-weight: 400;
    color: rgb(176, 170, 170);
}

.review-box .description {
    display: flex;
    justify-content: space-between;
}

.review-box .description .quote {
    font-size: 60px;
    font-weight: 700;
    font-family: math;
    background: linear-gradient(to right, #A7FDCC, #1FA0FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: inline;
}

.review-box .description .review {
    padding: 15px 0px 10px 10px;
    font-size: 17px;
   
}


    .login_section {
     
        padding: 40px 80px 37px 80px;
        position: relative;
    }

    .login_section .heading {
        font-size: 32px;
        font-weight: 600;
    }
    .model-image {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .login .reduced-image {
        width: 75%;
        height: auto;
    }
    
    .signup .reduced-image {
        width: 75%;
        height: auto;
    }
   
.logo {
    margin-left: 75px;
}
}


@media only screen and (min-width:1256px) and (max-width:1476px) {
    .left {
        display: block;
    }

    .login_section {
     
        padding: 40px 174px;
        position: relative;
    }

    .login_section .heading {
        font-size: 32px;
        font-weight: 600;
    }
    .model-image {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .login .reduced-image {
        width: 75%;
        height: auto;
    }
    
    .signup .reduced-image {
        width: 75%;
        height: auto;
    }
    .review-box {
        height: 270px;
        width: 370px;
        background-color: white;
        padding: 18px 29px;
        position: absolute;
        right: 50px;
        font-family: "EpilogueItalic";
        bottom: 24px;
    
    }
    
.logo {
    margin-left: 75px;
}
}


@font-face {
    font-family: 'InterVariable';
    src: url('../../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal italic;
}

@font-face {
    font-family: 'EpilogueVariable';
    src: url('../../fonts/Epilogue/Epilogue-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'EpilogueVariable';
    src: url('../../fonts/Epilogue/Epilogue-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}